import { Inject, Injectable } from '@angular/core';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { PROD } from '@memberspot/frontend/shared/util/tokens';

import { CLIENT_URL } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class SchoolUrlService {
  get school() {
    return this._schoolsQuery.getActive();
  }

  constructor(
    private _schoolsQuery: SchoolsQuery,
    @Inject(PROD) private _prod: boolean,
    @Inject(CLIENT_URL) private _clientUrl: string,
  ) {}

  getPostDetailUrl(courseId: string, chapterId: string, postId: string) {
    return `${this.getClientUrl()}/library/${courseId}/${chapterId}/${postId}`;
  }

  getClientUrl() {
    const url = this._clientUrl;

    if (this._prod) {
      return this.school?.externalUrl
        ? `https://${this.school?.url}`
        : `https://${this.school?.url}.${url}`;
    } else {
      return `http://${this.school?.url}.localhost:4300`;
    }
  }

  getRealtivePostDetailUrl(
    courseId: string,
    chapterId: string,
    postId: string,
  ) {
    return `/library/${courseId}/${chapterId}/${postId}`;
  }
}
